<template>
  <div id="app">
    <div class="mobile-wrap center">
        <div class="tops">
            <div class="logo">
                <img src="@/assets/logo.png" alt="">
            </div>
            <div class="txts">
                8878<em class="webtxt">{{ dmText }}</em>.tv
            </div>
        </div>
        <!-- <div class="banner">
            <img src="https://wuniang7.aawvw.com/dh02/1.gif" alt="">
        </div> -->
        <div class="notice">
            <div class="ico">
                <img src="@/assets/45x35.png" alt="">
            </div>
            <div class="txt">
                <marquee>由于行业原因，本APP被某杀毒软件认定为病毒，请大家放心使用,安装APP不会对您的手机有任何安全隐患！！8878a.tv--8878z.tv(后面26个字母任意一个即可)</marquee>
            </div>
        </div>
        <div class="line">
            <div v-for="item,key in lines" :key="key" class="item">
                <div class="ms"><em>{{ item.time }}</em>ms</div>
                <div class="txts txts-bg">{{ item.text }}</div>
                <div class="button fr">
                    <a><button class="dl-button">立即下载</button></a>
                </div>
            </div>
        </div>
        <div class="buttons">
            <a href="https://d4k620tfgt9lb.cloudfront.net/mj.html" target="_blank">
            <button></button></a>
        </div>
        <div class="text">
            <p>
                永久回家页地址：您好，这边还请您点击
            </p>
            <p>
                <em>8878a.tv</em> 到<em>8878z.tv</em> 
            </p>
            <p>
                进入我公司最新网址下载登陆呢
            </p>
            <p>
                给您带来不便，还请见谅!
            </p>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      lines: [
        {time: 0, text: 'VIP下载线路1'},
        {time: 0, text: 'VIP下载线路2'},
        {time: 0, text: 'VIP下载线路3'},
        {time: 0, text: 'VIP下载线路4'},
        {time: 0, text: 'VIP下载线路5'},
        {time: 0, text: 'VIP下载线路6'},
        ],
      dmText: '',
      arr: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
      arrIndex: 0,
    }    
  },
  methods: {
    getRandom(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    web() {
      this.arrIndex++;
      if (this.arrIndex < 26) {
          this.dmText = this.arr[this.arrIndex]
      } else {
        this.arrIndex = 0;
          this.dmText = this.arr[this.arrIndex]
      }
      // console.log(this.arrIndex)
    }
  },
  mounted() {
    setInterval(this.web, 1000);
    for (var j = 0; j < this.lines.length; j++) {
      var random = this.getRandom(1, 20);
      this.lines[j].time = random;
    }
    document.title = '官网下载路线'
  }
}
</script>

<style>
.move-touch {
  -webkit-overflow-scrolling: touch
}

.auto-center ul li .pict {
  display: table;
  width: 100%;
  text-align: center
}

.auto-center ul li .pict i {
  vertical-align: middle;
  display: table-cell
}

.vague {
  -webkit-filter: blur(2px);
  transition: .3s;
  -webkit-transition: .3s
}

.rogate {
  transition: .3s;
  -webkit-transition: .3s
}

.rogate:hover {
  transform: rotate(90deg)
}

.arrow {
  position: relative;
  line-height: 1;
  margin-left: .1rem
}

.arrow:before {
  content: "";
  position: absolute;
  top: -.02rem;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: .08rem solid;
  border-right: .08rem solid transparent;
  border-top: .08rem solid transparent;
  border-left: .08rem solid transparent
}

.arrow:after {
  content: "";
  position: absolute;
  top: .18rem;
  left: 0;
  width: 0;
  height: 0;
  border-top: .08rem solid;
  border-right: .08rem solid transparent;
  border-bottom: .08rem solid transparent;
  border-left: .08rem solid transparent
}

.arrow.one:before {
  display: none
}

.arrow.one:after {
  top: .14rem
}

.border-top:before {
  background: #dadada;
  content: "";
  position: absolute;
  height: 1px;
  transform-origin: 0 0;
  transform: scale(.5, .5);
  -webkit-transform: scale(.5, .5);
  -moz-transform: scale(.5, .5);
  width: 200%;
  top: 0;
  left: 0
}

.border-bottom:after {
  background: #dadada;
  content: "";
  position: absolute;
  height: 1px;
  transform-origin: 0 0;
  transform: scale(.5, .5);
  -webkit-transform: scale(.5, .5);
  -moz-transform: scale(.5, .5);
  width: 200%;
  bottom: -1px;
  left: 0
}

.flex-slide {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch
}

.flex-slide>* {
  flex-shrink: 0
}

.box-space-between {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between
}

.box-space-between>* {
  flex-shrink: 0
}

.touch-state {
  position: relative
}

.touch-state label {
  position: relative;
  background: #fff;
  display: block;
  width: .9rem;
  height: .45rem;
  border: 1px solid #ccc;
  border-radius: .3rem;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.touch-state label:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  width: .41rem;
  height: .41rem;
  background: #fff;
  box-shadow: 0 0 .1rem rgba(0, 0, 0, .4);
  border-radius: 50%;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.touch-state input {
  position: absolute;
  z-index: 0;
  opacity: 0;
  left: 45%;
  top: 20%
}

.touch-state input:checked+label {
  background: #47b92c;
  border-color: #47b92c
}

.touch-state input:checked+label:after {
  left: calc(100% - .41rem)
}

.swiper-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  display: -webkit-box;
  position: relative
}

.swiper-slide {
  flex-shrink: 0;
  position: relative
}

.mohu {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=1, MakeShadow=false)
}

.fixed-top {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 2;
  background: #fff
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: #fff
}

.column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex
}

.column>* {
  column-count: 1;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center
}

a,
body,
button,
dd,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
i,
img,
input,
li,
p,
select,
span,
strong,
textarea,
ul {
  margin: 0;
  padding: 0
}

b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 400
}

table {
  border-spacing: 0
}

body {
  font-family: "microsoft yahei", Arial;
  font-size: .26rem;
  line-height: 1;
  color: #333;
  -webkit-user-select: none;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important
}

::-webkit-scrollbar {
  width: 0;
  height: 0
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

li {
  list-style-type: none;
  float: left
}

a {
  text-decoration: none;
  color: #333;
  outline: 0
}

img {
  border: none;
  vertical-align: middle
}

em,
i,
var {
  font-style: normal
}

button {
  font-family: "microsoft yahei", Arial;
  outline: 0;
  cursor: pointer;
  font-size: .26rem;
  border: none
}

iframe {
  border: none
}

input,
select,
textarea {
  outline: 0;
  font-family: "microsoft yahei", Arial;
  font-size: .26rem;
  border-radius: 0;
  border: none;
  background: 0 0
}

input:disabled {
  opacity: 1;
  color: #333
}

button,
input[type=button],
input[type=date],
input[type=number],
input[type=password],
input[type=reset],
input[type=submit],
input[type=text],
textarea {
  -webkit-appearance: none;
  border: none;
  background: 0 0
}

textarea {
  resize: none
}

.ico {
  display: inline-block;
  vertical-align: middle
}

.fl {
  float: left
}

.fr {
  float: right
}

.clear {
  clear: both
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden
}

.clearfix {
  zoom: 1
}

.hide {
  display: none !important
}

.mobile-wrap {
  position: relative;
  min-height: 100vh;
  width: 100%;
  float: left
}

body,
html {
  width: 100%;
  height: 100%
}

.tops {
  width: 100%;
  height: .92rem;
  background-image: linear-gradient(to right, #9d1fd9, #f5ebd8);
  padding: 0 .28rem;
  display: flex;
  justify-content: space-between
}

.tops .logo {
  height: .92rem;
  display: flex;
  justify-content: center;
  align-items: center
}

.tops .logo img {
  width: 2.25rem
}

.tops .txts {
  line-height: .92rem;
  font-size: .5rem;
  font-weight: bold;
  color: #5f0000;
  text-transform: uppercase
}

.tops .txts em {
  color: red;
  -webkit-animation: myfirst 1s infinite;
  animation: myfirst 1s infinite;
  display: inline-block;
  padding: 0 .02rem
}

.banner {
  width: 100%;
  float: left
}

.banner  img {
  width: 100%;
  float: left
}


.notice {
  width: 100%;
  height: .62rem;
  background-color: #f1f1f1;
  float: left;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 .04rem .08rem rgba(0, 0, 0, .1)
}

.notice .ico {
  width: .9rem;
  height: .62rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: .08rem
}

.notice .ico img {
  width: .45rem
}

.notice .txt {
  width: calc(100% - .9rem);
  line-height: .62rem;
  font-size: .26rem;
  color: #000
}

.line {
  width: 100%;
  padding: .28rem .24rem;
  float: left
}

.line .item {
  width: 100%;
  height: .7rem;
  margin-bottom: .2rem
}

.line .item .ms {
  width: 1.4rem;
  height: .7rem;
  border: 1px solid #c9c9c9;
  border-radius: .06rem;
  box-shadow: inset 0 0 .16rem rgba(0, 0, 0, .1);
  text-align: center;
  line-height: .68rem;
  font-size: .28rem;
  float: left
}

.line .item .txts {
  width: 3.6rem;
  height: .7rem;
  border: 1px solid #c9c9c9;
  border-radius: .06rem;
  box-shadow: inset 0 0 .16rem rgba(0, 0, 0, .1);
  margin-left: .14rem;
  float: left;
  font-size: .28rem;
  text-align: center;
  line-height: .68rem;
}
.line .item .txts-bg{
  background: url(@/assets/crown.png) no-repeat 0.4rem 0.2rem;
  background-size: 0.32rem;
}

.line .button {
  width: 1.72rem;
  height: .7rem
}

.line .button button {
  width: 100%;
  height: .7rem;
  background-color: #9d1bd8;
  border-radius: .06rem;
  text-align: center;
  line-height: .7rem;
  font-size: .3rem;
  color: #fff
}

.buttons {
  width: 100%;
  text-align: center
}

.buttons button {
  width: 5.44rem;
  height: 1.06rem;
  background: url(@/assets/544x106.png) center no-repeat;
  background-size: 5.44rem 1.06rem;
  -webkit-animation: myfirst 1s infinite;
  animation: myfirst 1s infinite
}

.text {
  width: 100%;
  text-align: center;
  padding-top: .28rem;
  padding-bottom: .56rem
}

.text p {
  width: 100%;
  line-height: .46rem;
  font-size: .28rem;
  color: #696969
}

.text p em {
  color: #ff5d90
}

@-webkit-keyframes myfirst {
  from {
      transform: scale(1)
  }

  50% {
      transform: scale(.8)
  }

  to {
      transform: scale(1)
  }
}

@keyframes myfirst {
  from {
      transform: scale(1)
  }

  50% {
      transform: scale(.8)
  }

  to {
      transform: scale(1)
  }
}

.tops{
  height: auto;
  align-items: center;
  background-color: #8422a0;
  background-image: linear-gradient(141deg, #3b009f00 0%, #9f20c3 51%, #f16596 75%);
}
.button a button{
  background-color: #8422a0;
  background-image: linear-gradient(141deg, #8b00c952 0%, #8422a0 51%, #eb4c82 75%);
}
.tops .txts em{
  color: #fff;
}
.text p em{
  color: #1344e9;
}

</style>
